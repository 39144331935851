import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image';

const Home: React.FC = () => {
    const data = useStaticQuery(query);
    const index = Math.floor(Math.random() * data.allFile.edges.length);
       
    return (
        <div id="page-home">
            <BackgroundImage
                Tag="section"
                fluid={data.allFile.edges[index].node.childImageSharp.fluid}
                backgroundColor={`#000`} className="capa-background">
            
                <div className="row m-0 h-100">
                    <div className="col-md-6"></div>
                    <div className="col-sm-5 col-md-5 col-lg-5 main-title">
                        <div className="row h-100 align-items-center">
                            <div className="mx-auto">
                                <div className="p-2">
                                    <h1>TEIA <br/> ANANSI</h1>
                                </div>
                                <div className="p-2">
                                    <p className="h1">Coletiva de Mulheres Negras</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BackgroundImage>
        </div>
    );
}

export default Home;

export const query = graphql`
    query MyQuery {
      allFile(filter: {name: {regex: "/capa2/"}}) {
        edges {
          node {
            id
            name
            childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
          }
        }
      }
    }`
